import http from "../default";
import authHeader from "./AuthHeaderService";

// const getAll = (type:string, search:string) => { 
//   return http.get(`/carbon/v1/emission-factor?type=${type}&search=${search}`,{ headers: authHeader() });
// };
const getAll = (chart_type:string, industrial_park_id:string) => {
  return http.get(`/carbon/v1/embedded-link?chart_type=${chart_type}&industrial_park_id=${industrial_park_id}`, { headers: authHeader() });
};

const getId = (id:any) => {
  return http.get(`/carbon/v1/embedded-link/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/embedded-link", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/embedded-link/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/embedded-link/${id}`, { headers: authHeader() });
};

const EmbedLink = {
  getAll,
  create,
  update,
  remove,
  getId
};

export default EmbedLink;
