import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { round } from "lodash";


const TotalEmision = (data:any) => {
 
  var data_chart = []
  var category = []
  for (let index = 0; index < data.data.length; index++) {
    data_chart.push(data.data[index]["value"])
    category.push(moment(data.data[index]["datetime"]).format("MMM YYYY"))
  }
  
  const series = [
    {
      name: "Emissions",
      data: data_chart,
    },
    // {
    //   name: "series2",
    //   data: [32, 60, 34, 46, 34, 52, 41],
    // },
  ];

  const options: Object = {
    dataLabels: {
      enabled: false,
      formatter: (val: number) => {
        if (val>1000) {
          var count = Math.round((val) / 1000)
          return count + 'K'
        }
        else{
          return Math.round(val) + " Ton CO2e"

        }
      }
      
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#FFB850", "#34c38f"],
    xaxis: {
      categories:category,
      // type: "month",
    },
    yaxis: {
      title: {
        text: "Ton CO2e",
      },
      labels: {
        formatter: (val: number) => {
          if (val>1000) {
            var count = Math.round((val) / 1000)
            return count + 'K'
          }
          else{
            return Math.round(val) + " Ton CO2e"

          }
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y: {
        // formatter: function (y: number) {
        //   if (typeof y !== "undefined") {
        //     return y.toFixed(0) + " kg CO2e";
        //   }
        //   return y;
        // },
        formatter: (val: number) => {
          if (val>1000) {
            var count = Math.round((val) / 1000)
            return count + 'K'
          }
          else{
            return Math.round(val) + " Ton CO2e"
          }
        }
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="300"
      className="apex-charts"
    />
    // {test}
  );
};

export default TotalEmision;
