import React, { useState, useEffect } from "react";
import { 
  UncontrolledAccordion,
  UncontrolledCollapse,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container,
  Collapse,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  CardTitle,
  InputGroup,
  CardHeader, } from "reactstrap";
  import classnames from "classnames";

//Import Breadcrumb
import EmissionBreakdown from "../AllCharts/apex/analytics/EmissionBreakdown";
// Service
import AnalyticsService from '../../api/service/AnalyticsService';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Id } from "../Invoices/invoicelistCol";
import { Link } from "react-router-dom";

// Call Scss file
import avatar from "../../assets/images/users/avatar-2.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import "./styles.css";

import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
// import "../react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";


const Breakdown = () => {
  document.title = "Home | Carbon Platform";

  const initChartBreakdown ={
    area_name:"",
    total_emission:0,
    percentage:0,
    asset_emissions:[
      {
        asset_type: "",
        total_emission:0,
        percentage:0,
        total_cost_emission:0
      }
    ]
  }
  const initAreaEmission = {
    area_name:"",
    monthly_emissions:[
      {
        datetime: "",
        value: ""
      }
    ]
  }

  const [ChartBreakdown, setChartBreakdown] = useState([initChartBreakdown]);
  const [ChartTotalEmissionUnit, setChartTotalEmissionUnit] = useState([initAreaEmission]);
  // const [FilterDate, setFilterDate] = useState("");
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);
  var start = "";
  var end = "";
  if(FilterDate!=null){
    start = moment(FilterDate[0]).format("YYYY-MM-DD");
    end = moment(FilterDate[1]).format("YYYY-MM-DD")
    if (start==end) {
      start=""
      end = ""
    }
  }
  else {
      start=""
      end = ""
  }
  
  useEffect(() => {
    AnalyticsService.getBreakdownFilter(start, end)
    .then(response => {
      setChartBreakdown(response.data.data.area_breakdown_table);
      
    })
    .catch(e => {
        console.log(e);
      });

    AnalyticsService.getOverviewFilter(start, end)
    .then(response => {
      setChartTotalEmissionUnit(response.data.data.area_monthly_chart);
    })
    .catch(e => {
        console.log(e);
      });

  }, [FilterDate])

  const sum = ChartBreakdown.reduce((accumulator, object) => {
    return accumulator + object.total_emission;
  }, 0);
  

  // console.log(ChartBreakdown)
  

  // const testing = [1,2,3];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Analytics" breadcrumbItem="Emission Breakdown" />
          <Row className="justify-content-end">

            <Col xs={3}>
              <div className="mt-2 mt-sm-0">
                {/* <InputGroup>
                    <Flatpickr
                      className="form-control align-self-center"
                      placeholder="Periode"
                      value={FilterDate}
                      onChange={(e:any) => setFilterDate(e)}
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                    />
                  </InputGroup>
                  <br /> */}
                <Stack direction="column" className="float-end" spacing={8} alignItems="flex-start">
                      
                  <DateRangePicker
                    // value={FilterDate}
                    onChange={(e:any) => setFilterDate(e)}
                    // ranges={predefinedRanges}
                    placement="bottomEnd"
                    placeholder="Periode"
                    style={{ width: 300 }}
                  />
                </Stack>
                <br />
                <br />
              </div>

            </Col>

          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <div className="d-flex flex-wrap align-items-center mb-4">
                    <h5 className="card-title me-2">Emission (ton CO2e)</h5>
                  </div> */}
                  <Row className="align-items-center">
                    <Col xl={12}>
                      <EmissionBreakdown data={ChartTotalEmissionUnit} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <br />
            {/* <h4>Emission Components </h4> */}
            <h5 className="mb-3">
              Emission Components{" "} 
              {/* <span className="ms-1 badge badge-soft-success text-primary font-size-12">
                Data last month
              </span> */}
              {/* <small className="text-muted"><code> - Data Last Month </code></small> */}
            </h5>
            <br /><br />
            <Col>
            <Card>
              <CardBody>
                <Row className="row-table">
                  <Col xl={2}>
                    <span className=" text-table-accord"><strong>Assets</strong> </span>
                  </Col>
                  <Col xl={5}>
                    <span></span>
                  </Col>
                  <Col xl={2}>
                    <span><strong>Emissions in Ton CO2e</strong> </span>
                  </Col>
                  <Col xl={3}>
                    <span><strong>Cost in IDR</strong></span>
                  </Col>
                </Row>
                
                <Row>
                  <Card className="bg-success text-white text-left p-2">
                    <Row>
                      <Col xl={2}>
                        <span className="text-table-accord">Net Total</span>
                      </Col>
                      <Col xl={5}>
                        <span></span>
                      </Col>
                      <Col xl={2}>
                        <span>{Math.round(sum).toLocaleString('en-US')}</span>
                        
                      </Col>
                      <Col xl={3}>
                        {/* <span>Rp 1,275,364,908,- </span> */}
                        <span>NA </span>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                {
                  ChartBreakdown.map((item,row)=>(
                    <Row key={item.area_name}>
                      <Col xl={2}>
                        <span className="text-table-accord"><strong>{item.area_name}</strong></span>
                      </Col>
                      <Col xl={5}>
                        <div className="mb-4 postion-progress-bar">
                          <Progress color="primary" value={item.percentage}></Progress>
                        </div>
                      </Col>
                      <Col xl={2}>
                        <span>{Math.round(item.total_emission).toLocaleString('en-US')}</span>
                      </Col>
                      <Col xl={2}>
                        {/* <span>Rp 1,275,364,908,-  </span> */}
                        <span>0</span>
                      </Col>
                      <Col xl={1}>
                        <div>
                          <Button
                            className="btn btn-light"
                            color="primary"
                            id={"test"+row}
                            style={{
                              marginBottom: '1rem'
                            }}
                          >
                            <i className="fas fa-angle-down"></i>
                          </Button>
                        </div>
                      </Col>
                      <hr />
                      
                      <UncontrolledCollapse toggler={"#test"+row}>
                        {
                          item.asset_emissions.map((detail,index)=>(
                            <React.Fragment key={detail.asset_type}>
                              <Row>
                                <Col xl={2}>
                                  <span className="text-table-accord">{detail.asset_type}</span>
                                </Col>
                                <Col xl={5}>
                                  <div className="mb-4 postion-progress-bar">
                                    <Progress color="secondary" value={detail.percentage}></Progress>
                                  </div>
                                </Col>
                                <Col xl={2}>
                                  <span>{Math.round(detail.total_emission).toLocaleString('en-US')}</span>
                                  {/* <span>{detail.total_emission.toFixed(2)}</span> */}
                                </Col>
                                <Col xl={2}>
                                  <span>{detail.total_cost_emission}</span>
                                </Col>
                              </Row>
                              <hr/>
                            </React.Fragment>
                            
                          ))
                        }
                                         
                      </UncontrolledCollapse>
                      
                    </Row>
                  ))
                }
                
                
                
              </CardBody>
            </Card>
            
            
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Breakdown;
 