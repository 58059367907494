import ReactApexChart from "react-apexcharts";
import React from "react";
import { round } from "lodash";
import moment from "moment";

const EmissionBreakdown = (data:any) => {
  var data_chart = []
  var category = []
  for (let index = 0; index < data.data.length; index++) {
    var temp = []
    for (let val = 0; val < data.data[index]["monthly_emissions"].length; val++) {
      if (index==0) {
        category.push(moment(data.data[index]["monthly_emissions"][val]["datetime"]).format("MMM YYYY"));
      }
        temp.push(Math.round(data.data[index]["monthly_emissions"][val]["value"]));
      
    }
    data_chart.push(
      {
        name:data.data[index]["area_name"],
        data:temp
      }
    )
  }
  
  // const series = [
  //   // {
  //   //   name: 'Office',
  //   //   color: '#36A7A7',
  //   //   data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
  //   // },
  // ];
  const options: Object = {
    chart: {
        height: 350,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      yaxis: {
        title: {
          text: "Ton CO2e",
        },
        labels: {
          formatter: (val: number) => {
            if (val>1000) {
              var count = Math.round((val) / 1000)
              return count + 'K'
            }
            else{
              return Math.round(val) + " Ton CO2e"
    
            }
          }
        }
      },
      colors: ['#36A7A7', '#A73651','#6E77C9','#D58E17'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        },
        dataLabels: {
          formatter: (val: number) => {
            if (val>1000) {
              var count = Math.round((val) / 1000)
              return count + 'K'
            }
            else{
              return Math.round(val) + " Ton CO2e"
    
            }
          }
        },
      }],
      tooltip: {
        y: {
          formatter: (val: number) => {
            if (val>1000) {
              var count = Math.round((val) / 1000)
              return count + 'K'
            }
            else{
              return Math.round(val) + " Ton CO2e"
    
            }
          },
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        // type: '',
        categories: category,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      // color: ['#36A7A7', '#A73651','#6E77C9','#D58E17'],
      // color: ['#36A7A7', '#A73651','#6E77C9','#D58E17'],
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "bottom",
        horizontalAlign: "left",
        verticalAlign: "left",
        floating: false,
        fontSize: "20px",
        offsetX: 0,
        offsetY: 10,
        
      },
      fill: {
        opacity: 1
      }
  };
  return (
    <ReactApexChart options={options} series={data_chart} type="bar" height={350} className="apex-charts" />
  );
};
export default EmissionBreakdown;
