import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
// import { Button, Tooltip } from 'reactstrap';

import { 
  Container,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Tooltip,
  Button
} from "reactstrap";
// Date range
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
// Component  Chart
import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels";
import ColumnWithMarker from "../AllCharts/apex/ColumnWithMarker";
import { WidgetsData } from "../../common/data/dashboard";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

// Asset
import img3 from "../../assets/images/small/img-3.jpg";
import icon_mr from "../../assets/images/brands/bg-icon-mr.svg";
import icon_cdp from "../../assets/images/brands/bg-con-cr.svg";
import icon_cost_report from "../../assets/images/brands/bg-icon-cost-report.svg";

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
// import "../react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import ReactPaginate from "react-paginate";

import Swal from 'sweetalert2';



import "./styles.css";


import AnalyticsService from '../../api/service/AnalyticsService';
import DataCollectionService from '../../api/service/DataCollectionService';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";
import { error } from "console";


const Home = () => {
  document.title = "Home | Carbon Platform";
  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: (value:any) => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: (value:any) => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()]
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)]
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())]
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()]
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()]
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()]
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)]
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
    }
  ];
  const initMonthlyEmission = {
    month: "",
    actual: 0,
    expected: 0,
  }
  const initAreaOverview = {
    area_name: "",
    total_emission: 0,
    total_asset: 0,
  }
  const initialCollectDataState = {
    id: "",
    created_at:"",
    date_start: "",
    date_end: "",
    file_name:"",
    uploaded_by: "",
    file_link: "",
    tenant_name: "",
    tenant_id: "",
    calculated:false
  };
  const [CollectData, setCollectData] = useState([initialCollectDataState]);
  const [ttop, setttop] = useState(false);


  const [ChartTotalEmission, setChartTotalEmission] = useState([initMonthlyEmission]);
  const [ChartTotalEmission1, setChartTotalEmission1] = useState([initMonthlyEmission]);
  const [AreaOverview, setAreaOverview] = useState([initAreaOverview]);
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);

  const [Notification, setNotification] = useState([initialCollectDataState]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  
  //  Handle Filter Date range for data null and split data
  var start = "";
  var end = "";
  if(FilterDate!=null){
    start = moment(FilterDate[0]).format("YYYY-MM-DD");
    end = moment(FilterDate[1]).format("YYYY-MM-DD")
    if (start==end) {
      start=""
      end = ""
    }
  }
  else {
    start=""
    end = ""
  }
  
  

  useEffect(() => {
    
    AnalyticsService.getHomeFilter(start,end)
    .then(response => {
      // setChartTotalEmission1(response.data.data.montly_emission_chart);
      setChartTotalEmission(response.data.data.montly_emission_chart);
      setAreaOverview(response.data.data.area_overview)
    })
    .catch(error => {
      setChartTotalEmission([])
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Data "+error.response.data.message+" !"
      })
    });
    DataCollectionService.getAll("","","")
    .then(response => {
      setCollectData(response.data.data.emission_datas);
    })
    .catch(e => {
      console.log(e);
    });
  }, [FilterDate])

  useEffect(() => {
    const endOffset = dataOffset + 5;
    setNotification(CollectData.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(CollectData.length / 5));
  }, [CollectData, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 5) % CollectData.length;
    setDataOffset(newOffset);
  };
  
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" link_title="/home" breadcrumbItem="Home" />
          {/* <br /> */}
          <Row className="justify-content-end">
            {/* <Col>
            <Stack direction="column" spacing={8} alignItems="flex-start">
                
                <DateRangePicker
                  // ranges={predefinedRanges}
                  placeholder="Placement left"
                  style={{ width: 300 }}
                />
               
              </Stack>
            </Col> */}
            <Col xs={4}>
              <div className="mt-2 mt-sm-0">
                {/* <InputGroup>
                    <Flatpickr
                      // data-enable-time
                      className="form-control align-self-center"
                      
                      placeholder="Periode"
                      value={FilterDate}
                      onChange={(e:any) => setFilterDate(e)}
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                    />
                  </InputGroup> */}
                  <Stack direction="column" className="float-end" spacing={8} alignItems="flex-start">
                    
                    <DateRangePicker
                      // value={FilterDate}
                      onChange={(e:any) => setFilterDate(e)}
                      // ranges={predefinedRanges}
                      placement="bottomEnd"
                      placeholder="Periode"
                      style={{ width: 300 }}
                    />
                  </Stack>
                  <br />
                  <br />
                </div>

            </Col>

          </Row>
          <Row>
            <Col xl={7}>
              <Card className="btn-rounded shadow-sm">
                <CardBody>
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h5 className="card-title me-2">Emission (ton CO2e)</h5>
                  </div>
                  <Row className="align-items-center">
                    <Col xl={12}>
                      <ColumnWithMarker data={ChartTotalEmission} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={5}>
              <Card className="btn-rounded shadow-sm">
                <CardHeader>
                  <h4 className="card-title mb-0">Notifications</h4>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Level</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Notification.map((raw,index)=>(
                          <tr key={raw.id}>
                            <td>{moment(raw.created_at).format("YYYY-MM-DD") }</td> 
                            <td className="text-success">
                              <div className={"badge badge-soft-" + "success" + " font-size-12"}>
                                success
                                </div>
                            </td>
                            <td>Upload Data - &nbsp;
                            {raw.file_name.length > 20 ?
                                `${raw.file_name.substring(0, 20)}...` : raw.file_name
                              }
                            </td>
                          </tr>
                          ))
                        }
                        
                        
                      </tbody>
                    </Table>
                  </div>
                  <br />
                  <div className="text-sm-end">
                    <div className="justify-content-end pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row> 
            <h4>Area List</h4>
           
            {/* <Widgets options={options} /> */}
            {
              AreaOverview.map((item, key) => (
                <Col xl={2} md={3} key={key}>
                  {/* <Tooltip
                    key={key}
                    placement="top"
                    isOpen={tooltipOpen}
                    target={"TooltipTop-" + key}
                    toggle={toggle}
                    // toggle={() => {
                    //   setttop(!ttop);
                    // }}
                  >
                    {item.area_name}
                  </Tooltip> */}
                <Link to="#"  >
                {/* <Link to="#" id={"TooltipTop-" +key } > */}
                
                  {/* <button
                      type="button"
                      className="btn btn-primary"
                      id={"TooltipTop"+key}
                    >
                      {" "}
                      Tooltip on top
                    </button> */}
                  <Card className="btn-rounded shadow-sm">
                    <div className="card-header bg-success border-success">
                      <h5 className="my-0 text-white">
                        <i className="mdi mdi-bullseye-arrow me-3"></i>
                        {item.area_name.length > 10 ?
                          `${item.area_name.substring(0, 10)}...` : item.area_name
                        }
                        
                      </h5>
                    </div>
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          {/* <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            {item.area_name}
                          </span> */}
                          <h4 className="mb-3">
                            {/* {widget.isDoller === true ? "$" : ""} */}
                            <span className="counter-value">
                              { Math.round(item.total_emission).toLocaleString('en-US')} <small className="text-muted"> tCO2e</small>
                              {/* <CountUp
                                start={0}
                                end={item.total_emission}
                                duration={3}
                              /> */}
                              {/* {widget.postFix} */}
                            </span>
                          </h4>
                        </Col>
                        
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-success text-success" 
                          }
                        >
                          {item.total_asset}
                        </span>
                        <span className="ms-1 text-muted font-size-13">
                          Total Asset
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Link>

              </Col>
              ))
            }
            
          </Row>
          <br />
          <Row>
            <h4>Report List</h4>
            <Col lg={4}>
              <Link to="/report/monthly">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Monthly Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_mr} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
            <Col lg={4}>
              <Link to="/report/cdp">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Summary Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_cdp} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
            <Col lg={4}>
              <Link to="/report/cost">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Emission Type Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_cost_report} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
 
export default Home;
