import React, {useEffect, useMemo, useState} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  Input,
  Label,
 } from "reactstrap";

import ReactPaginate from "react-paginate"; 
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

import IndustrialService from '../../../api/service/IndustrialService'
import TenantService from '../../../api/service/TenantService'

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import { mapKeys } from "lodash";

const TenantPage = () => {
  document.title = "Emission Factor | Carbon Platform";
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  
  const initCreate = {
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0,
    business_sector: "",
    industrial_park_id: ""
  }
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [Tenant, setTenant] = useState([initTenant]);
  
  const [filter_type , setFiltertype] = useState("")
  const [search_name , setSearchName] = useState("")

  const [delete_status, setdelete_status] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [status_save, setstatus_save] = useState(false);


  // Pagination
  const [currentData, setCurrentData] = useState([initTenant]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  
  // Set Validation
  useEffect(()=>{
    if (bodyAsset.name!="" && bodyAsset.legal_name!="" && bodyAsset.industrial_park_id) {
      setstatus_save(true);
    }else{
      setstatus_save(false);
    }
  },[bodyAsset.name, bodyAsset.legal_name, bodyAsset.industrial_park_id])

  // Get Data
  useEffect(()=>{
    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));
    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));
    
  },[ filter_type, search_name, modal_xlarge, delete_status])

  useEffect(() => {
    const endOffset = dataOffset + 10;
    setCurrentData(Tenant.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(Tenant.length / 10));
  }, [Tenant, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % Tenant.length;
    setDataOffset(newOffset);
  }; 

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    var data = {
      name: bodyAsset.name,
      legal_name: bodyAsset.legal_name,
      head_count: bodyAsset.head_count,
      location_latitude: bodyAsset.location_latitude,
      location_longitude: bodyAsset.location_longitude,
      location_country: bodyAsset.location_country,
      location_area_ha: bodyAsset.location_area_ha,
      business_sector: bodyAsset.business_sector,
      industrial_park_id: bodyAsset.industrial_park_id
      
    }
    console.log(data)
    TenantService.create(data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setbodyAsset(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this Asset!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        TenantService.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your Asset has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tenant" link_title="/site-management/tenant" breadcrumbItem="Tenant" />
          <Row>
            <Col xl={12}>
              <Card>
                <div className="table-responsive">
                  <CardHeader>
                    <Row>
                      <Col xl={10}>
                      </Col>
                      <Col xl={2}>
                        {/* <Button className="float-end btn btn-success btn-sm">Tambah</Button> */}
                        <button
                          type="button"
                          className="btn btn-light waves-effect waves-light btn-sm float-end"
                          onClick={() => {
                            tog_xlarge();
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-xl"
                        >
                          <i className="bx bx-plus me-1"></i> Add Entry
                        </button>
                        <div>
                        <Modal
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              New Tenant
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Modal Content */}
                          <div className="modal-body">
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Name</Label>
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="name"
                                name="name"
                                value={bodyAsset.name}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Legal Name</Label>
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="legal_name"
                                name="legal_name"
                                value={bodyAsset.legal_name}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Bussines Sector</Label>
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="business_sector"
                                name="business_sector"
                                value={bodyAsset.business_sector}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
                              <select 
                                value={bodyAsset.industrial_park_id} 
                                onChange={handleInputChange}
                                placeholder="Industrial Park"
                                id="industrial_park_id"
                                name="industrial_park_id"
                                className="form-select">
                                    <option value="">Select Industrial Park</option>
                                    {
                                      Industrial?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw.id}
                                          >{raw.legal_name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Head Count</Label>
                              <Input 
                                className="form-control" 
                                type="number" 
                                id="head_count"
                                name="head_count"
                                value={bodyAsset.head_count}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Row>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Location Latitude</Label>
                                  <Input 
                                    className="form-control" 
                                    type="number" 
                                    id="location_latitude"
                                    name="location_latitude"
                                    value={bodyAsset.location_latitude}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Location Longitude</Label>
                                  <Input 
                                    className="form-control" 
                                    type="number" 
                                    id="location_longitude"
                                    name="location_longitude"
                                    value={bodyAsset.location_longitude}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                
                              </Row>
                            </div>
                            <div className="mb-3">
                              <Row>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Location Country</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="location_country"
                                    name="location_country"
                                    value={bodyAsset.location_country}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Location Area Ha</Label>
                                  <Input 
                                    className="form-control" 
                                    type="number" 
                                    id="location_area_ha"
                                    name="location_area_ha"
                                    value={bodyAsset.location_area_ha}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                
                              </Row>
                            </div>
                            

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Cancel
                              </button>
                              {
                                <button 
                                  type="button"
                                  disabled={status_save ? false : true}
                                  onClick={() => {
                                    save();
                                  }}
                                  className="btn btn-success">
                                  Save
                                </button>
                                }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                      </Col>
                    </Row>
                    
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="align-middle mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Name</th>
                            <th>Legal Name</th>
                            <th>Head Count </th>
                            <th>Location Longitude - Latitude</th>
                            <th>Location Country</th>
                            <th>Industrial Park</th>
                            <th>Location Area</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            currentData.length >0 ? (
                              currentData.map((raw,index)=>(
                                <tr key={raw.id}>
                                  <td>{raw.name}</td>
                                  <td>{raw.legal_name}</td>
                                  <td>{raw.head_count}</td>
                                  <td>
                                    <div className={"badge badge-soft-" + "success" + " font-size-12"}> {raw.location_longitude} </div> , &nbsp; 
                                    <div className={"badge badge-soft-" + "success" + " font-size-12"}> {raw.location_latitude} </div>
                                  </td>
                                  <td>{raw.location_country}</td>
                                  <td>
                                    {Industrial.map((data,i)=>
                                      data.id==raw.industrial_park_id ? data.name : ""
                                    )}
                                  </td>
                                  <td> {raw.location_area_ha} Hektar</td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <Link className="text-success" to={"/site-management/tenant/edit/"+raw.id}>
                                        <i
                                          className=" bx bx-pencil font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </Link>
                                      <a className="text-danger" href="#"
                                        onClick={() => {handleDelete(raw.id)}} 
                                      >
                                        <i
                                          className=" bx bx-trash-alt font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ):
                            (
                              <tr>
                                <td colSpan={7}>No Data Emission Factor</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                    <div className="text-sm-end">
                      <div className="justify-content-end pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TenantPage;
