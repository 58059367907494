import React from "react";
import ReactApexChart from "react-apexcharts";
import TotalEmision from "./TotalEmision";
import moment from "moment";
import { round } from "lodash";


const TotalEmisionUnit = (data:any) => {
  // const series = [
  //   {
  //     name: "WWTP1",
  //     data: [169497.94, 219787.25, 209149.82],
  //   },
  //   {
  //     name: "WWTP2",
  //     data: [47317.44, 48353.88, 55768.11],
  //   },
  //   {
  //     name: "WTP",
  //     data: [212221.26, 175870.3143, 177940.2342],
  //   },
  // ];
  // console.log(data.data)
  var data_chart = []
  var category = []
  for (let index = 0; index < data.data.length; index++) {
    var temp = []
    for (let val = 0; val < data.data[index]["monthly_emissions"].length; val++) {
      if (index==0) {
        category.push(moment(data.data[index]["monthly_emissions"][val]["datetime"]).format("MMM YYYY"));
      }
        temp.push(data.data[index]["monthly_emissions"][val]["value"]);
        // temp.push(Math.round(data.data[index]["monthly_emissions"][val]["value"]));
      
    }
    data_chart.push(
      {
        name:data.data[index]["area_name"],
        data:temp
      }
    )
  }
  console.log(data_chart)

  const options: Object = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    // colors: ["#556ee6", "#34c38f", "#89375F"],
    xaxis: {
      // type: "datetime",
      categories: category,
    },
    yaxis: {
      title: {
        text: "Ton CO2e",
      },
      labels: {
        formatter: (val: number) => {
          if (val>0) {
            return (val/1000).toFixed(1) + "K"
            
          }
          else {
            return val;
          }
          // return (val/1000).toFixed(1) + "K"

          // var count = Math.round((val) / 1000)
          // return count + 'K'
          // if (val>1000) {
          //   var count = Math.round((val) / 1000)
          //   return count + 'K'
          // }
          // else{
          //   return (val/1000).toFixed(1) + "K"

          // }
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y:{
        formatter: (val: number) => {
          if (val>1000) {
            var count = Math.round((val) / 1000)
            return count + 'K'
          }
          else{
            return Math.round(val) + " Ton CO2e"
  
          }
        } 
      }
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={data_chart}
      type="area"
      height="350"
      className="apex-charts"
    />
  );
};

export default TotalEmisionUnit;
