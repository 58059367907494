import React , {useState} from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledAlert
} from "reactstrap";

import PropTypes from "prop-types";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link,useNavigate } from "react-router-dom";

import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions"; 

// import images
import logo from "../../assets/images/musagreen_header_logo-3.png";
import logo_musa from "../../assets/images/logo-musa.png";
import img1 from "../../assets/images/users/air-industri.png";
import img2 from "../../assets/images/users/obat-industri.jpg";
import img3 from "../../assets/images/users/server-industri.png";

//Import config
import config from "../../config";
import CarouselPage from "../AuthenticationInner/CarouselPage";

import AuthService from '../../api/service/AuthService';


interface LoginProps {
  history: object;
}

const Login = (props:any) => {
  const dispatch = useDispatch();
  const [status_login, setStatusd] = useState("");
  const navigate = useNavigate();

  // const [status_login, setStatusd] = useState(false);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@musagreen.com" || "demo-jababeka@musagreen.com" || "",
      // password: "12345678" || "j4babeka-demo" || "",
      email: "",
      password:  "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // if (onsubmit) {
        // dispatch(loginUser(values, props.router.navigate));
        
      // }
      console.log(values)
      AuthService.login(values)
      .then(response => {
        // console.log(response.data)
        // localStorage.setItem("user", JSON.stringify(response.data.data.token));
        localStorage.setItem("user", response.data.data.token);
        navigate("/home");
      })
      .catch(e => {

        // console.log(e.response.data.message);
        setStatusd(e.response.data.message)
      });

    },
  });

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
    
  }));
  console.log( error)
  // if (error[0]=='Username and password are invalid. Please enter correct username and password') {
  //   setStatusd(true)
  // }
  const signIn = (res: any, type: any) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response: Object) => {
    signIn(response, "google");
  };

  //handleFacebookLoginResponse
  const facebookResponse = (response: Object) => {
    signIn(response, "facebook");
  };


  document.title = "Login | Carbon Platform";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/home" className="d-block auth-logo">
                        <img src={logo} alt="" height="80" />{" "}
                        {/* <span className="logo-txt">Carbon Platform</span> */}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back to Carbon Platform!</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue 
                        </p>
                      </div>
                      {/* {error[0]} */}
                      {
                        status_login ?
                        <UncontrolledAlert
                          color="danger"
                          className="alert-top-border"
                        >
                          <i className="mdi mdi-block-helper me-3 align-middle text-danger"></i>
                          <strong>{ status_login }</strong>
                        </UncontrolledAlert>
                        :
                        ""
                      }
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div> */}

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-success btn-block"
                                type="submit"
                                // onClick={() => logIn()}
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>

                      {/* <div className="mt-4 text-center"> */}
                        {/* <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={config.facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={(renderProps: any) => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li> */}
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          {/* <li className="list-inline-item">
                            <GoogleLogin
                              clientId="CLIENT_ID" // u can add your Client ID
                              render={(renderProps) => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul> */}
                      {/* </div> */}

                      {/* <div className="mt-1 text-center">
                        <p className="text-muted mb-0">
                          Don't have an account ?{" "}
                          <Link
                            to="/register"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div> */}
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Musa Green . Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Musa Green
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} md={7} className="col-xxl-9">
              <div className="auth-bg pt-md-5 p-4 d-flex" >
                <div className="bg-overlay bg-info-subtle	"></div>
                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              {/* <img className="card-img img-fluid" src={img1} alt=""/> */}
            </Col>
            {/* <CarouselPage /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};