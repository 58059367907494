import http from "../default";
import authHeader from "./AuthHeaderService";

const getOverview = () => {
  return http.get("/carbon/v1/statistic-overview", { headers: authHeader() });
};

const getOverviewFilter = (start:string, end:string) => {
  return http.get(`/carbon/v1/statistic-overview?date_start=${start}&date_end=${end}`,{ headers: authHeader() });
};

const getBreakdown = () => {
  return http.get("/carbon/v1/statistic-breakdown", { headers: authHeader() });
};

const getBreakdownFilter = (start:string, end:string) => {
  return http.get(`/carbon/v1/statistic-breakdown?date_start=${start}&date_end=${end}`, { headers: authHeader() });
};

const getHome = () => {
  return http.get("/carbon/v1/homepage", { headers: authHeader() });
};

const getHomeFilter = (start:string, end:string) => {
  return http.get(`/carbon/v1/homepage?date_start=${start}&date_end=${end}`, { headers: authHeader() });
};


const AnaliticsService = {
  getOverview,
  getBreakdown,
  getHome,
  getHomeFilter,
  getBreakdownFilter,
  getOverviewFilter
};

export default AnaliticsService;
