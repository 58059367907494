import React , {useState, useEffect} from "react";
import { 
  Container,
  Row,
  Button,
  UncontrolledCollapse,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  Col,
  CardHeader
   } from "reactstrap";
   import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import { isNull } from "lodash";
import EmbedLink from '../../../api/service/EmbedLink';

const ReportCDP = () => {
  document.title = "Monthly Report | Carbon Platform";
  const initChart = {
    id:"",
    link:"",
    chart_type:"",
    industrial_park_id:""
  }
  const [customCDP, setcustomCDP] = useState("");
  
  const [showName, setShowName] = useState(false);
  const [showCustom1, setShowCustom1] = useState(false);
  const [chart, setchart] = useState([initChart]);
  const [embedchart, setembedchart] = useState("");
  
  const [delete_status, setdelete_status] = useState(0);
  const [status_save, setstatus_save] = useState(0);
  // localStorage.setItem('CS1','default');
  useEffect(() => {
    // memanggil API untuk mengambil data todos
    //Call API
    var param = {
      type : "cdp",
      industrial_park_id:""
    }
    EmbedLink.getAll(param.type, param.industrial_park_id)
    .then(res => {
      // console.log("res data", res)
      if (res.data.data.length>0) {
        setchart(res.data.data);
        setembedchart(res.data.data[0]["link"])
      }
     
    }).catch(err => console.log(err));
    console.log("chart",chart[0].chart_type)
    //End API
  }, [ delete_status, status_save]);
  const save = ()=> {
    
    if (chart[0].id=="") {
      const data = {
        link:embedchart,
        chart_type:"cdp",
        industrial_park_id:localStorage.getItem('industrial_id')
      }
      EmbedLink.create(data)
      .then(response => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your data saved!',
          showConfirmButton: false,
          timer: 1500
        })
        setstatus_save(status_save+1);
      })
      .catch(e => {
        console.log(e);
      });
    }else{
      const data = {
        link:embedchart,
        chart_type:"cdp",
      }
      EmbedLink.update(chart[0].id,data)
      .then(response => {
        // swal("Saved!", "Your data saved!", "success");
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your data Updated!',
          showConfirmButton: false,
          timer: 1500
        })
      setstatus_save(status_save+1);    

      })
      .catch(e => {
        console.log(e);
      });
    }
  }
  
  const handleReset = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure Reset this Chart!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reset it!'
    }).then((result) => {
      if (result.isConfirmed) {
        EmbedLink.remove(id)
        .then(response => {
          Swal.fire(
            'Reseted!',
            'Your Chart has been Reseted!',
            'success'
          )
          setdelete_status(delete_status+1);
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    window.location.reload();
    
  }; 
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Report" link_title="/report" breadcrumbItem="Summary Report" />
          <Row>
            <div>
              <Button
                color="success"
                id="togglerCDP"
                style={{
                  marginBottom: '1rem'
                }}
              >
                Chart Custom
              </Button>
              <UncontrolledCollapse toggler="#togglerCDP">
                <Card>
                  <CardBody>
                    {/* <Form > */}
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="formrow-firstname-input">
                          Custom Summary Report
                        </Label>
                        <Input
                          value={embedchart}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          placeholder="Input Url"
                          onChange={(e) => setembedchart(e.target.value)}
                        />
                      </div>
                      

                      <div className="mt-4">
                        {/* <button type="submit" className="btn btn-primary w-md" onClick={handleSubmitCR} > */}
                        <button type="submit" className="btn btn-primary w-md" onClick={save} >
                          Submit
                        </button> &nbsp;
                        
                      </div>
                    {/* </Form> */}
                    

                    
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </div>
          </Row>

          <Row>
            <div>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="mb-4">
                        <span className="logo-txt">Custom Summary Report</span>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="mb-4">
                      {embedchart!="" ?
                        <button  type="reset" className=" float-end btn btn-danger btn-sm" onClick={()=>handleReset(chart[0].id)} >
                          Reset
                        </button> 
                        :
                        ""
                        }
                      </div>
                    </div>
                  </div>
                  <p>
                    <strong>
                    Please Re-input your username and password for second layer security and refresh the browser after that.
                    </strong>
                  </p>
                  {
                    embedchart!=''?
                    <iframe
                    width="100%"
                    height="450"
                    seamless
                    frameBorder="0"
                    scrolling="auto"
                    src={`${embedchart}`}

                    >
                    </iframe>
                    :
                    <div>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder w-75"></span>{" "}
                        <span className="placeholder" style={{ width: "25%" }}></span>
                    </div>
                  }
                  
                </CardBody>
              </Card>
            </div>
            
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportCDP;
